import React from 'react'

export default function Profile({page}) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 21 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={page === "profile" ? "#C33B4C" : "white"}
        d="M9.86028 11.5609C11.4485 11.5609 12.8238 10.9912 13.9475 9.86729C15.0713 8.74361 15.6409 7.36867 15.6409 5.78027C15.6409 4.19234 15.0713 2.81727 13.9473 1.69317C12.8234 0.569623 11.4483 0 9.86028 0C8.27183 0 6.89685 0.569623 5.77317 1.69335C4.64949 2.81708 4.07963 4.1922 4.07963 5.78027C4.07963 7.36867 4.64944 8.74379 5.77336 9.86752C6.89727 10.9911 8.27239 11.5609 9.86028 11.5609ZM19.9748 18.4548C19.9423 17.9871 19.8768 17.4769 19.7803 16.9383C19.6829 16.3955 19.5575 15.8825 19.4073 15.4136C19.2523 14.9289 19.0413 14.4503 18.7806 13.9916C18.5099 13.5155 18.1921 13.1009 17.8354 12.7598C17.4624 12.403 17.0057 12.1161 16.4776 11.9067C15.9514 11.6986 15.3682 11.5931 14.7444 11.5931C14.4994 11.5931 14.2625 11.6936 13.8049 11.9916C13.4796 12.2034 13.1534 12.4137 12.8262 12.6225C12.5118 12.8228 12.0859 13.0105 11.5598 13.1804C11.0466 13.3465 10.5255 13.4308 10.0111 13.4308C9.49681 13.4308 8.97584 13.3465 8.46205 13.1804C7.93658 13.0107 7.51068 12.823 7.19662 12.6227C6.8324 12.39 6.50287 12.1776 6.21703 11.9913C5.76 11.6934 5.52286 11.5929 5.27789 11.5929C4.65384 11.5929 4.07086 11.6985 3.54479 11.907C3.01707 12.1159 2.56023 12.4028 2.18687 12.76C1.83039 13.1013 1.51234 13.5157 1.24206 13.9916C0.98153 14.4502 0.770594 14.9287 0.615297 15.4138C0.465298 15.8827 0.339908 16.3955 0.242502 16.9383C0.145987 17.4762 0.0804557 17.9866 0.0480184 18.4553C0.0157507 18.927 -0.000243096 19.3997 6.54139e-05 19.8725C6.54139e-05 21.1254 0.398314 22.1395 1.18365 22.8875C1.95929 23.6257 2.98557 24.0001 4.23361 24.0001H15.7898C17.0378 24.0001 18.0637 23.6258 18.8395 22.8876C19.6251 22.1401 20.0233 21.1257 20.0233 19.8723C20.0231 19.3888 20.0068 18.9118 19.9748 18.4548Z"
      />
    </svg>
  );
}

import React from 'react'

export default function Dashboard({page}) {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={page === "dashboard" ? "#C33B4C" : "white"}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.11117 13.8332C7.11117 14.2748 7.29875 14.6991 7.63201 15.0115C7.96529 15.324 8.41786 15.4998 8.88892 15.4998H14.2221C14.6932 15.4998 15.1458 15.324 15.479 15.0115C15.8123 14.6991 15.9999 14.2748 15.9999 13.8332V10.4998C15.9999 10.0582 15.8123 9.63391 15.479 9.32148C15.1458 9.00905 14.6932 8.83319 14.2221 8.83319H8.88892C8.41786 8.83319 7.96527 9.00905 7.63201 9.32148C7.29873 9.63393 7.11117 10.0582 7.11117 10.4998V13.8332ZM3.55556 8.83336H1.77776C1.30652 8.83336 0.854103 9.00922 0.520841 9.32165C0.187385 9.63409 0 10.0584 0 10.5V13.8334C0 14.275 0.187407 14.6993 0.520841 15.0117C0.854121 15.3242 1.30652 15.5 1.77776 15.5H3.55556C4.02662 15.5 4.47904 15.3241 4.81248 15.0117C5.14576 14.6993 5.33332 14.275 5.33332 13.8334V10.5C5.33332 10.0584 5.14574 9.63408 4.81248 9.32165C4.47902 9.0092 4.02662 8.83336 3.55556 8.83336ZM16 2.16664C16 1.72485 15.8124 1.30072 15.4791 0.988285C15.1458 0.675672 14.6933 0.5 14.2222 0.5H1.7778C1.30656 0.5 0.854147 0.675693 0.520885 0.988285C0.18743 1.30073 4.53665e-05 1.72485 4.53665e-05 2.16664V5.5C4.53665e-05 5.94162 0.187452 6.36575 0.520885 6.67835C0.854165 6.9908 1.30656 7.16664 1.7778 7.16664H14.2222C15.2045 7.16664 16 6.42082 16 5.49996L16 2.16664Z"
      />
    </svg>
  );
}
